// import nodemodules
import React, { Component } from "react";
import { Link } from "gatsby";
import { PrivacyPolicy } from "@tyson-foods/react-component-library";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

class PrivacyPolicyComponent extends Component {
  render() {
    return (
      <Layout
          metaTitle="Privacy Policy | Galileo® Salame"
          metaDescription="Privacy policy regarding information collected on the Galileo® Salame website." 
      >
        <div className="gagl-privacy-policy-container gagl-nav-top-space">
          <PrivacyPolicy
            className="px-4 px-xl-5 gagl-legal-pages"
            aboutOurAdsLink={<Link to="/about-our-ads/">HERE</Link>}
            termsOfUseLink={<Link to="/terms-of-use/">terms of use</Link>}
          />
        </div>
      </Layout>
    );
  }
}

export default PrivacyPolicyComponent;
